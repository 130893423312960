<template>
  <div>
    <b-card-group deck>
      <b-card header="Tạo mới thông tin mốc nhận thưởng" header-tag="header">
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-6">
              <b-form-group id="input-dk-2" label="Tên bảng xếp hạng(*)" label-for="ms-title">
                <b-form-input id="ms-title" v-model="form.title" required maxlength="200" placeholder="Tên bảng xếp hạng"
                  :class="[{ 'is-invalid': form.errors.has('title') }]"></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-dk-2" label="Mô tả bảng xếp hạng" label-for="ms-title">
                <b-form-input id="ms-title" v-model="form.description" maxlength="200" placeholder="Mô tả"
                  :class="[{ 'is-invalid': form.errors.has('description') }]"></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>

            <!-- Ảnh bảng xếp hạng -->
            <b-form-group label="Banner" class="col-md-6">
              <div>
                <b-form-file @change="onImageChange($event, 'icon')" placeholder="chọn ảnh" accept="image/*" type="file"
                  :class="[{ 'is-invalid': form.errors.has('icon') }]"></b-form-file>
                <div style="width: 100px; height: 50px; margin-top:2px" v-if="form.banner">
                  <img style="width: 100px;" :src="form.banner" alt="" />
                </div>
                <has-error :form="form" field="icon"></has-error>
              </div>
            </b-form-group>
            <!-- Thời gian bắt đầu thử thách -->
            <div class="col-md-3" style="margin-top: 6px;">
              <b-form-group id="input-group-4" label="Thời gian bắt đầu diễn ra thử thách (*):" label-for="d3"
                class="col-md-12">
                <date-picker id="d3" name="dp3" v-model="form.date_start" type="datetime" placeholder="Chọn thời gian"
                  :input-attr="{ required: 'true', name: '' }" format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"></date-picker>
              </b-form-group>
            </div>

            <!-- Thời gian kết thúc mốc -->
            <div class="col-md-3" style="margin-top: 6px;">
              <b-form-group id="input-group-4" label="Thời gian kết thúc thử thách (*):" label-for="d4" class="col-md-12">
                <date-picker id="d4" name="dp4" v-model="form.date_end" type="datetime" placeholder="Chọn thời gian"
                  :input-attr="{ required: 'true', name: '' }" format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"></date-picker>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <!-- Trạng thái -->
            <div class="col-md-6">
              <b-form-group id="input-dk-2" label="Trạng thái" label-for="ms-title">
                <b-form-checkbox id="checkbox-1" v-model="form.status" value="1" unchecked-value="0" size="lg">
                  Kích hoạt
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <!-- Mốc -->
          <FormMileStone @data-mile-stone="receiveDataFromMileStone" :dataMile="components" />
          <hr />
          <div class="row">
            <div class="col-md-12" style="text-align: right">
              <b-button v-if="!is_edit" type="submit" variant="primary" style="margin-right: 10px" @click="saveForm">
                Tạo mới
              </b-button>
              <b-button v-else type="submit" variant="primary" style="margin-right: 10px" @click="updateChallengeRank">
                Cập nhật
              </b-button>
              <router-link v-if="is_edit" :to="'/campaign/mileStone'">
                <b-button variant="danger">Hủy bỏ</b-button>
              </router-link>
              <b-button v-else type="reset" variant="danger">Làm mới</b-button>
            </div>
          </div>
        </b-form>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import { Form } from "vform";
import { HasError } from "vform/src/components/bootstrap5";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import FormMileStone from "./FormMileStone.vue";

Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormConfigRanks",
  mixins: [Common],
  components: {
    DatePicker,
    FormMileStone,
  },
  data() {
    return {
      form: new Form({
        id: "",
        title: "",
        description: "",
        type: 1,
        banner: "",
        status: 0,
        date_start: "",
        mileStone: [],
        date_end: "",
      }),
      clients: [],
      events: [],
      show: true,
      is_edit: false,
      is_disable: false,
      scripts: [],
      components: [], // Danh sách các component con
      challengeMileStone: []
    };
  },
  computed: {
    clientID() {
      return this.form.client_id;
    },
  },
  watch: {
    clientID() {
      this.loadEvents();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí bảng xếp hạng ", route: "/campaign/mileStone" },
    ]);
  },
  methods: {
    receiveDataFromMileStone(data) {
      this.form.mileStone = data;
    },
    saveForm() {
      this.form
        .submit("post", "/campaign/challenge-rank")
        .then(({ data }) => {
          if (1 === data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: data.message,
            });
            this.$router.push({ name: "mileStone" });
          }
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Vui lòng kiểm tra lại form nhập liệu.",
          });
        });
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    async loadEvents() {
      try {
        this.$bus.$emit("show-loading", true);
        let filter = {
          client_id: this.form.client_id,
          is_show_all: true,
        };
        let response = await WalletCampaignRepository.getEvents(filter);
        this.$bus.$emit("show-loading", false);
        this.events = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async loadChallengeRank(id) {
      try {
        let response = await CampaignRepository.getChallengeRankDetail(id);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          this.form.fill(data);
          if (data.status == 2) {
            this.is_disable = true;
          }
          if (data && data.mile_stone) {
            let that = this;
            that.components = data.mile_stone;
          }
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    async updateChallengeRank() {
      this.form
        .submit("post", "/campaign/challenge-rank/" + this.form.id)
        .then(({ data }) => {
          if (1 === data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: data.message,
            });
            this.$router.push({ name: "mileStone" });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "error",
            title: error,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        title: "",
        description: "",
        type: 1,
        banner: "",
        status: "",
        date_start: "",
        mileStone: [],
        date_end: "",
      };
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 2) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.form.banner = response.data.data.link;
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    async getScripts() {
      let response = await WalletCampaignRepository.getListAllScripts();
      if (response.data.data) {
        response.data.data.forEach((item) => {
          this.scripts.push({
            value: item.id,
            text: item.event.name,
          });
        });
      }
    },
  },
  created() {
    this.getScripts();
    this.getClients();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadChallengeRank(this.$route.params.id);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.title-block {
  color: #ff0000;
  font-size: 18px;
  font-weight: bold;
}

.datetime-picker div input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#input-group-4 {
  padding: 0px;
}

.mx-datepicker {
  width: 100%;
}
</style>
