<!-- ChildComponent.vue -->
<template>
  <div>
    <div v-for="(item, index) in dataMileStone" :key="index">
      <div style="display: flex;justify-content: space-between;">
        <h2 class="title-block">MỐC {{ index + 1 }}</h2>
        <div>
          <b-button class="btn btn-danger" style="margin-right: 10px" @click="deleteMileStone(index)">
            Xóa mốc BXH
          </b-button>
        </div>
      </div>
      <hr />
      <div class="row">
        <!-- Tên mốc nhận thưởng -->
        <div class="col-md-6">
          <b-form-group id="input-dk-2" label="Tên mốc(*)" label-for="ms-title">
            <b-form-input id="ms-title" v-model="dataMileStone[index].title" v-on:input="handleInput" required
              maxlength="200" placeholder="Tên mốc" :class="[{ 'is-invalid': form.errors.has('code') }]"></b-form-input>
            <has-error :form="form" field="title"></has-error>
          </b-form-group>
        </div>
        <!-- Số lượt đạt mốc -->
        <div class="col-md-6">
          <b-form-group id="input-dk-2" label="Số lượt đạt mốc(*)" label-for="ms-title">
            <b-form-input id="ms-title" type="number" v-model="dataMileStone[index].num" v-on:input="handleInput" required
              maxlength="200" placeholder="Số lượt đạt mốc"
              :class="[{ 'is-invalid': form.errors.has('code') }]"></b-form-input>
            <has-error :form="form" field="title"></has-error>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group id="input-type-gift" label="Kịch bản phát quà(*):" label-for="script-gift-type">
            <b-form-select id="script-gift-type" v-model="dataMileStone[index].script_id" @change="handleInput"
              :options="scripts" lable="" required placeholder="Chọn kịch bản phát quà"></b-form-select>
          </b-form-group>
        </div>
        <!-- Thông báo -->
        <div class="col-md-6">
          <b-form-group id="input-dk-2" label="Tiêu đề thông báo" label-for="ms-title">
            <b-form-input id="ms-noti" v-model="dataMileStone[index].noti_title" v-on:input="handleInput" maxlength="200"
              placeholder="Tiêu đề thông báo" :class="[{ 'is-invalid': form.errors.has('code') }]"></b-form-input>
            <has-error :form="form" field="title"></has-error>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <!-- Nội dung thông báo -->
          <b-form-group id="input-dk-2" label="Nội dung thông báo" label-for="ms-desc">
            <b-form-textarea id="ms-desc" v-model="dataMileStone[index].noti_desc" v-on:input="handleInput"
              placeholder="Nhập nội dung thông báo" maxlength="500" rows="6"></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <hr />
    </div>
    <!-- Nút thêm mới mốc -->
    <div class="row">
      <b-button variant="primary" style="margin-right: 10px" @click="addMileStone">
        + Thêm mốc BXH
      </b-button>
    </div>

  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import { Form } from "vform";
import { HasError } from "vform/src/components/bootstrap5";
import Vue from "vue";

Vue.component(HasError.name, HasError);
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
export default {
  props: {
    dataMile: {
    },
  },
  data() {
    return {
      component: [],
      dataMileStone: [{
        title: "",
        num: "",
        script_id: "",
        noti_title: "",
        noti_desc: "",
        status: 1
      }],
      form: new Form({
        title: "",
        num: "",
        script_id: "",
        noti_title: "",
        noti_desc: "",
      }),
      scripts: [],
      displayNone: true
    };
  },
  computed: {
    clientID() {
      return this.dataMile;
    },
  },
  watch: {
    clientID() {
      this.loadMilStone();
    },
  },
  methods: {
    async getScripts() {
      let response = await WalletCampaignRepository.getListAllScripts();
      if (response.data.data) {
        response.data.data.forEach((item) => {
          this.scripts.push({
            value: item.id,
            text: item.event.name,
          });
        });
      }
    },
    handleInput() {
      this.$emit("data-mile-stone", this.dataMileStone);
    },
    addMileStone() {
      this.dataMileStone.push({
        title: "",
        num: "",
        script_id: "",
        noti_title: "",
        noti_desc: "",
        status: 1,
      });
    },
    loadMilStone() {
      this.dataMileStone = this.dataMile;
    },
    deleteMileStone(index) {
      const indexToRemove = index; // Vị trí index của phần tử cần loại bỏ
      this.dataMileStone.splice(indexToRemove, 1);
      this.$emit("data-mile-stone", this.dataMileStone);
    }
  },
  created() {
    this.getScripts();
  },
};
</script>
